<template>
  <header class="header" :class="{'onWhite':headerClass && !headerMobile,'onMobile': headerMobile}">
    <div class="header_row">
      <div class="header_logo">
        <router-link to='/'>
          <div class="logo_roll">
              <img src="../assets/image/img/logo.png">
              <img src="../assets/image/img/logo-m.png">
          </div>
        </router-link>
      </div>
      <div class="nav">
        <ul class="nav_list">
          <li class="nav_a"
            v-for="itemA in data"
            :key="itemA.catid"
          >
            <router-link :to="{
              path:itemA.biaoshi,
              query:{
                catid:itemA.catid,
                cid:itemA.child[0].catid,
              }
            }" @click="TypeAni">
              <span class="nav_a_name hoverable_txt">{{itemA.name}}</span>
            </router-link>
            <div class="nav_b" v-if="itemA.child.length > 1">
              <ul class="nav_b_list">
                <li v-for="itemB in itemA.child" :key="itemB.catid">
                  <router-link :to="{
                    path:itemB.biaoshi,
                    query:{
                      catid:itemA.catid,
                      cid:itemB.catid
                    }
                  }">
                    {{itemB.name}}
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="other">
        <!-- <div class="other_item">
          <a href="javascript:void(0)">
            <i class="iconfont">&#xe605;</i>
          </a>
        </div> -->
        <div class="other_item">
          <a href="http://oa.cdwccr.com:8080">
            <span>oa</span>
          </a>
        </div>
        <div class="other_item">
          <a href="https://exmail.qq.com/login">
            <i class="iconfont">&#xe605;</i>
          </a>
        </div>
        <div class="other_item inquiry">
          <a href="javascript:void(0)">
            <i class="iconfont">&#xe622;</i>
          </a>
          <div class="_form">
            <div>
              <div class="_dd">
                 <input type="text" v-model="query" v-on:keydown.enter="enterPressHadlerSearch" placeholder="请输入关键词">
              </div>
              <div class="_dt">
                 <button type="button" @click="submits"><i class="iconfont">&#xe622;</i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="Hmenu">
        <a class="Hmenu_btn" @click="Hmenubtn" href="javascript:void(0)">
          <span></span>
          <span></span>
          <span></span>
        </a>
      </div>
    </div>
  </header>
</template>

<script>
import { reactive, toRefs, inject, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
export default {
  name: 'Header',
  setup () {
    const navData = inject('navData')
    const datas = reactive({
      headerMobile: false,
      headerClass: false,
      data: navData,
      query: '',
      name: ''
    })
    const router = useRouter()
    router.beforeEach((to, from, next) => {
      if (to.name === 'Inquiry') {
        datas.headerClass = true
      }
      datas.name = to.name
      next()
    })
    window.addEventListener('scroll', () => {
      const scrollTop = window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop
      if (scrollTop >= 80) {
        datas.headerClass = true
      } else {
        if (datas.name === 'Inquiry') {
          datas.headerClass = true
        } else {
          datas.headerClass = false
        }
      }
    })
    const Hmenubtn = () => {
      datas.headerMobile = !datas.headerMobile
    }
    const TypeAni = () => {
      sessionStorage.setItem('typeAni', 1)
    }
    const submits = () => {
      if (datas.query.replace(/\s*/g, '')) {
        router.push({ path: '/inquiry', query: { q: datas.query.replace(/\s*/g, '') } })
      }
    }
    const route = useRoute()
    const windowSize = document.body.clientWidth
    watch(() => route.query, () => {
      if (windowSize <= 992) {
        datas.headerMobile = false
      }
    }, { immediate: true })

    const enterPressHadlerSearch = e => {
      if (e.keyCode === 13) {
        submits()
      }
    }

    const { headerClass, data, headerMobile, query } = toRefs(datas)
    return { headerClass, data, Hmenubtn, headerMobile, TypeAni, query, submits, enterPressHadlerSearch }
  }
}
</script>

<style lang="scss">
@import '../assets/css/style.scss';
.header{
  position:fixed;
  top:0;
  left:0;
  z-index:999;
  padding:0 5%;
  transition:background-color 0.5s ease;
  -webkit-transition:background-color 0.5s ease;
  width:100%;
  @include flat-only{
    height:.65rem;
    padding:0 5%;
  }
  &_row{
    @include Flex;
    align-items:center;
    height:100%;
    .Hmenu{
      display:none;
      margin-left:.2rem;
      @include flat-only{
        display: block;
      }
      &_btn{
        align-items:center;
        outline:none;
        width:.28rem;
        height:.28rem;
        display: block;
        position: relative;
        span{
          background-color:#fff;
          position:absolute;
          width:100%;
          height:2px;
          left:0;
          border-radius:2px;
          -webkit-border-radius:2px;
          -webkit-transition:ease 0.4s;
          transition:ease 0.4s;
          &:nth-child(1){
            top:4px;
          }
          &:nth-child(2){
            top:50%;
            transform:translateY(-50%);
            -webkit-transform:translateY(-50%);
            width:65%;
          }
          &:nth-child(3){
            bottom:4px;
          }
        }
      }
    }
    .header_logo>a{
      overflow:hidden;
      display:block;
      height:.48rem;
      @include flat-only{
        height:.36rem;
      }
      .logo_roll{
          transition:transform .35s ease;
          -webkit-transition:transform .35s ease;
          height:200%;
          img{
            height:50%;
          }
      }
    }
    .nav{
      margin-left:auto;
      padding-right:.25rem;
      @include minpc-only{
        padding-right:.15rem;
      }
      @include flat-only{
        position:fixed;
        top:0;
        right:0;
        overflow:hidden;
        width:100%;
        height:100vh;
        padding-right:0;
        z-index:-2;
        padding-top:.65rem;
        transform: translateX(100%);
        background-color:#fff;
        transition: transform 0.78s cubic-bezier(0.7,0,0.3,1);
        -webkit-transition: transform 0.78s cubic-bezier(0.7,0,0.3,1);
        will-change:transform;
        .nav_list{
          opacity:0;
          transition: all 0.45s;
          overflow-y:auto;
          max-height:100%;
          padding:7vw 5%;
          .nav_a{
            padding-bottom:.25rem;
            &:last-child{
              padding-bottom:0;
            }
            >a{
              display:inline-block;
              height:auto;
              line-height:1.6;
              padding:0;
              font-weight:bold;
            }
            .nav_b{
              position:static;
              transform:none;
              opacity:1;
              visibility: visible;
              text-align:left;
              box-shadow:none;
              padding:0;
              margin-top:.2rem;
              &_list{
                @include Clearfix;
                li{
                  float:left;
                  padding-right:.3rem;
                  padding-bottom:.15rem;
                  >a{
                    padding:0;
                    display:inline-block;
                    line-height:1.6;
                  }
                }
              }
            }
          }
        }
      }
      &_list{
        @include Clearfix;
        .nav_a{
          @include Float(left);
          position:relative;
          @include LineAni;
          @include flat-only{
            float:none;
          }
          >a{
            display:block;
            color:$Color;
            font-size:.16rem;
            padding:0 .25rem;
            -webkit-transition:.35s ease;
            transition:.35s ease;
            height:.86rem;
            line-height:.86rem;
            @include minpc-only{
              padding:0 .15rem;
            }
            @include flat-only{
              color:$Color3;
            }
            .nav_a_name{
              line-height:1.6;
              &:after{
                background-color:$Background;
              }
            }
          }
          .nav_b{
            position:absolute;
            top:100%;
            left:50%;
            padding:.1rem 0;
            color:$Color3;
            min-width:1.8rem;
            transform:translateX(-50%) translateY(-.15rem);
            -webkit-transform:translateX(-50%) translateY(-.15rem);
            @include BorderRadius(5px);
            background:$Background;
            @include TextAlign(center);
            opacity:0;visibility: hidden;
            overflow: hidden;
            z-index:5;
            font-size:0.15rem;
            @include BoxShadow(0,0,10px,rgba(0,0,0,.1));
            -webkit-transition:0.6s linear,opacity .3s;
            transition:0.6s linear,opacity .3s;
            &_list>li>a{
              display:block;
              white-space:nowrap;
              line-height:3.2;
              color:$Color4;
              -webkit-transition:all 0.2s;
              transition:all 0.2s;
              padding:0 .15rem;
              &:hover{
                @include flathover-only {
                  background:$Background8;
                  color:$Color1;
                }
              }
            }
          }
          &:hover .nav_b{
            @include flathover-only {
              opacity:1;visibility:visible;
              transform:translateX(-50%) translateY(0);
              -webkit-transform:translateX(-50%) translateY(0);
            }
          }
        }
      }
    }
    .other{
      @include Flex;
      padding-left:.5rem;
      position: relative;
      @include minpc-only{
        padding-left:.3rem;
      }
       @include flat-only{
        padding-left:0;
        margin-left: auto;
      }
      &::before{
        content: '';
        position:absolute;
        top:50%;
        left:0;
        height:50%;
        transform:translateY(-50%);
        width:1px;
        background-color:rgba(255,255,255,.3);
        transition: all .2s;
        @include flat-only{
          display:none;
        }
      }
      &_item{
        padding-right:.12rem;
        >a{
          width:.34rem;
          height:.34rem;
          @include flat-only{
            width:.32rem;
            height:.32rem;
          }
          @include Flex;
          align-items:center;
          justify-content: center;
          color:$Color;
          background-color:rgba(255,255,255,.2);
          @include BorderRadius(50%);
          transition: all .2s;
          i{
            font-size:.2rem;
            @include flat-only{
              font-size:.18rem;
            }
          }
          span{
            text-transform: uppercase
          }
          &:hover{
            @include flathover-only{
              color:$Color6;
              background-color:$Background;
            }
          }
        }
        &:last-child{
          padding-right:0;
        }
      }
    }
  }
}
.header.onMobile{
  background-color:#fff;
  .Hmenu .Hmenu_btn span:nth-child(1){
    top:12px;transform:rotate(-45deg);-webkit-transform:rotate(-45deg);
  }
  .Hmenu .Hmenu_btn span:nth-child(2){
    opacity:0;-webkit-opacity:0;visibility:hidden;
  }
  .Hmenu .Hmenu_btn span:nth-child(3){
    bottom:12px;transform:rotate(45deg);-webkit-transform:rotate(45deg);
  }
  .nav{
    transform:none;
    &_list{
      opacity:1;
      transition-delay:0.5s;
      >.nav_a>a{
        color:$Color3;
        .hoverable_txt:after{
          background-color:$Background3;
        }
      }
    }
  }
  .header_logo>a .logo_roll{
    transform:translateY(-50%);
    -webkit-transform:translateY(-50%);
  }
  .Hmenu .Hmenu_btn span{
    background-color:$Background9;
  }
  .other{
    &::before{
      background-color:#ccc;
    }
    &_item{
      >a{
        background-color:#ccc;
        &:hover{
          @include flathover-only{
            color:$Color;
            background-color:$Background9;
          }
        }
      }
    }
  }
}
.header.onWhite{
  background-color:#fff;
  @include BoxShadow(0,0,10px,rgba(0,0,0,.1));
  .header_logo>a .logo_roll{
    transform:translateY(-50%);
    -webkit-transform:translateY(-50%);
  }
  .nav .nav_list>.nav_a>a{
    color:$Color3;
    .hoverable_txt:after{
      background-color:$Background3;
    }
  }
  .Hmenu .Hmenu_btn span{
    background-color:$Background9;
  }
  .other{
    &::before{
      background-color:#ccc;
    }
    &_item{
      >a{
        background-color:#ccc;
        &:hover{
          @include flathover-only{
            color:$Color;
            background-color:$Background9;
          }
        }
      }
    }
  }
}
.header .inquiry ._form{position:absolute;top:100%;right:0;padding-top: .2rem;transform:translateY(10px);opacity:0;visibility:hidden;transition:all cubic-bezier(.5,.5,0,1) 0.5s;}
.header .inquiry ._form>div{border:1px solid #e6e6e6;background-color: #fff;width: 280px;@include Flex;
@include BoxShadow(0, 0, 10px, rgba(0, 0, 0, .1))}
.header .inquiry ._form ._dd{padding:0 15px;@include Flexitem;}
.header .inquiry ._form ._dd>input{border:none;background:none;height:44px;width:100%;color:#333;font-size: 16px;}
.header .inquiry ._form ._dd>input::-webkit-input-placeholder{color:#d1d1d1;}
.header .inquiry ._form ._dd>input:-moz-placeholder{color:#d1d1d1;}
.header .inquiry ._form ._dd>input::-moz-placeholder{color:#d1d1d1;}
.header .inquiry ._form ._dd>input:-ms-input-placeholder{color:#d1d1d1;}
.header .inquiry ._form ._dt{position: relative;}
.header .inquiry ._form ._dt:before{content:'';width:1px;height:100%;background-color:#e6e6e6;position:absolute;left:0;top:0;}
.header .inquiry ._form ._dt>button{border:none;background:none;width:44px;height:44px;font-size: 24px;}
.header .inquiry:hover ._form{transform:none;opacity:1;visibility:visible;}

@include flat-only{
.header .inquiry ._form{padding-top: 15px;}
}

</style>
