import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home/Home')
  },
  {
    path: '/contactfrom',
    name: 'ContactFrom',
    component: () => import('../views/contact/ContactFrom')
  },
  {
    path: '/contactmode',
    name: 'ContactMode',
    component: () => import('../views/contact/ContactMode')
  },
  {
    path: '/joinhr',
    name: 'JoinHr',
    component: () => import('../views/join/JoinHr')
  },
  {
    path: '/joinrecruit',
    name: 'JoinRecruit',
    component: () => import('../views/join/JoinRecruit')
  },
  {
    path: '/joinidea',
    name: 'JoinIdea',
    component: () => import('../views/join/JoinIdea')
  },
  {
    path: '/newsdetail',
    name: 'NewsDetail',
    component: () => import('../views/news/NewsDetail')
  },
  {
    path: '/newslist',
    name: 'NewsList',
    component: () => import('../views/news/NewsList')
  },
  {
    path: '/policy',
    name: 'Policy',
    component: () => import('../views/policy/Policy')
  },
  {
    path: '/servicepmip',
    name: 'ServicePmip',
    component: () => import('../views/service/ServicePmip')
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import('../views/service/Service')
  },
  {
    path: '/aboutculture',
    name: 'AboutCulture',
    component: () => import('../views/about/AboutCulture')
  },
  {
    path: '/abouthonor',
    name: 'AboutHonor',
    component: () => import('../views/about/AboutHonor')
  },
  {
    path: '/staffstyle',
    name: 'StaffStyle',
    component: () => import('../views/about/StaffStyle')
  },
  {
    path: '/aboutunion',
    name: 'AboutUnion',
    component: () => import('../views/about/AboutUnion')
  },
  {
    path: '/aboutleader',
    name: 'AboutLeader',
    component: () => import('../views/about/AboutLeader')
  },
  {
    path: '/aboutcourse',
    name: 'AboutCourse',
    component: () => import('../views/about/AboutCourse')
  },
  {
    path: '/aboutus',
    name: 'AboutUs',
    component: () => import('../views/about/AboutUs')
  },
  {
    path: '/aboutteam',
    name: 'AboutTeam',
    component: () => import('../views/about/AboutTeam')
  },
  {
    path: '/platform',
    name: 'Platform',
    component: () => import('../views/service/Platform')
  },
  {
    path: '/servicepage',
    name: 'ServicePage',
    component: () => import('../views/service/ServicePage')
  },
  {
    path: '/inquiry',
    name: 'Inquiry',
    component: () => import('../views/home/Inquiry')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
export default router
