<template>
  <div id="main" @contextmenu.prevent
  :class="{'load':Number(load) === 1}"
  >
    <Header />
    <router-view/>
    <Footer v-if="nameURL != 'Service'"/>
  </div>
</template>
// @contextmenu.prevent
<script>
import { ref, getCurrentInstance, reactive, toRefs, provide, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Header from './components/Header'
import Footer from './components/Footer'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

export default {
  name: 'App',
  components: { Header, Footer },
  setup () {
    const nameURL = ref(null)
    const datas = reactive({
      navData: {},
      load: 0
    })
    const route = useRoute()
    const $http = getCurrentInstance().appContext.config.globalProperties.$http
    const msg = {
      catid: '1,2,3,4,5,6'
    }
    $http.get('fdata/category', msg).then(res => {
      if (res.code === 1) {
        datas.navData = res.data
      }
    })
    watch(() => route.query, () => {
      nameURL.value = route.name
    }, { immediate: true })
    const { navData, load } = toRefs(datas)
    provide('navData', navData)
    const router = useRouter()
    router.beforeEach((to, from, next) => {
      sessionStorage.setItem('path', to.name)
      if (Number(to.query.catid) !== 2 || Number(from.query.catid) !== 2 || to.query.delid || from.query.delid) {
        NProgress.start()
        datas.load = 0
        setTimeout(() => {
          next()
        }, 300)
      } else {
        next()
      }
    })
    router.afterEach((to) => {
      window.scrollTo(0, 0)
      NProgress.done()
    })
    const changeLoad = () => {
      datas.load = 1
    }

    NProgress.configure({
      ease: 'linear',
      speed: 300,
      showSpinner: false
    })
    provide('load', changeLoad)

    const debuggers = true
    if (!debuggers) {
      document.onkeydown = () => {
        if (window.event && window.event.keyCode === 123) {
          return false
        } else if (window.event.ctrlKey && window.event.shiftKey && window.event.keyCode === 73) {
          return false
        } else if (window.event.shiftKey && window.event.keyCode === 121) {
          return false
        }
      }
    }

    return { nameURL, navData, load, changeLoad }
  }
}
</script>
<style>
#nprogress .bar{
  background: #1a70d2 !important;
  height:3px !important;
}
#main{
  opacity:0;
  transition: all .3s;
}
@media (max-width:992px) {
   #main{overflow: hidden;}
}
#main.load{
  opacity:1;
  transform: none;
}
@media(min-width:768px) {
  .mobile_img{
    display:none;
  }
}
@media(max-width:768px) {
  .pc_img{
    display:none;
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: none;
  }
}
@keyframes fadeInUp {
  0% {
    transform: translateY(.8rem);
    opacity:0;
  }
  100% {
    transform: none;
    opacity:1;
  }
}
@keyframes fadeInRight {
  0% {
    transform: translateX(.8rem);
    opacity:0;
  }
  100% {
    transform: none;
    opacity:1;
  }
}
@keyframes zoomIn {
  0% {
    transform: scale(.7);
    opacity:0;
  }
  100% {
    transform: none;
    opacity:1;
  }
}
@keyframes fadeInLeft {
  0% {
    transform: translateX(-.8rem);
    opacity:0;
  }
  100% {
    transform: none;
    opacity:1;
  }
}

@media(min-width:992px) {
  .wow{visibility:hidden;}
}
</style>
