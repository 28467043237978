import { createStore } from 'vuex'
// import axios from 'axios'
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {

  },
  actions: {
    // getdata (store) {
    //   axios.get('/huaxi/fdata/category?catid=1,2')
    //     .then((res) => {
    //       store.commit('haederRes', res)
    //     })
    // }
  },
  modules: {
  }
})
