// axios
import axios from 'axios'
import { ElMessage } from 'element-plus'
axios.defaults.timeout = 10000
axios.defaults.baseURL = 'https://hx.cdwccr.com'
axios.defaults.withCredentails = true

// axios请求拦截
axios.interceptors.request.use(config => {
  return config
}, error => {
  return Promise.reject(error)
})
axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    if (error.response.status) {
      switch (error.response.status) {
        case 404:
          ElMessage.error('网络请求不存在')
          break
        case 500:
          ElMessage.error('内部服务器错误')
          break
        default:
          ElMessage.error(error.response.data.msg)
      }
      return Promise.reject(error.response)
    }
  }
)

/*
get方法
 */
export function get (url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      ElMessage.error('网络请求错误')
      reject(err.data)
    })
  })
}

/*
post方法
 */
export function post (url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, params).then(res => {
      resolve(res.data)
    }).catch(error => {
      ElMessage.error('网络请求错误')
      reject(error)
    })
  })
}

const http = {
  get,
  post
}
export default http
